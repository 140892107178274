import React, { Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import Layout from './components/Layout';

//import Login from "layouts/login/Login.js";

import Produtos from 'views/Produtos/Produtos.js';
import Clientes from 'views/Clientes/Clientes.js';
import Pedidos from 'views/Pedidos/Pedidos.js';
import Carrinho from 'views/Carrinho/Carrinho';
import Relatorios from 'views/Relatorios/relatorios.js';
import Home from 'views/Home/home.js';
import CONTASARECEBER from 'views/Relatorios/CONTASARECEBER/CONTASARECEBER';
import DashboardPage from "views/Dashboard/Dashboard.js";

//import Usuarios from "views/Usuarios/Usuarios.js";

const Usuarios = lazy(() => import('views/Usuarios/Usuarios'));
const Organizacao = lazy(() => import('views/Organizacao/Organizacao'));
const Empresa = lazy(() => import('views/Empresa/Empresa'));
const Precos = lazy(() => import('views/Precos/Precos'));
const Regras = lazy(() => import('views/Regras/Regras'));
const Galeria = lazy(() => import('views/Galeria/Galeria'));
const EGR1000 = lazy(() => import('views/Relatorios/EGR1000/EGR1000'));
const EIR4002 = lazy(() => import('views/Relatorios/EIR4002/EIR4002'));
const EIR6000 = lazy(() => import('views/Relatorios/EIR6000/EIR6000.js'));
const EIR6000COM = lazy(() => import('views/Relatorios/EIR6000/EIR6000COM.js'));
const PEDIDOSVENDA = lazy(() => import('views/Relatorios/PEDIDOSVENDA/PEDIDOSVENDA.js'));
const NOTASFISCAIS = lazy(() => import('views/Relatorios/NOTASFISCAIS/NOTASFISCAIS.js'));

const FICHATECNICA = lazy(() => import('views/Relatorios/FICHATECNICA/FICHATECNICA.js'));


const RELIMG = lazy(() =>
  import('views/Relatorios/ESTOQUEIMAGEM/ESTOQUEIMAGEM'),
);
const RELCONTASARECEBER = lazy(() =>
  import('views/Relatorios/CONTASARECEBER/CONTASARECEBER'),
);

const PROGRAMACAO = lazy(() =>
  import('views/Relatorios/PROGRAMACAO/PROGRAMACAO'),
);
const PRONTAENTREGA = lazy(() =>
  import('views/Relatorios/PRONTAENTREGA/PRONTAENTREGA'),
);
const VENDAANALITICO = lazy(() =>
  import('views/Relatorios/VENDAANALITICO/VENDAANALITICO'),
);
const VENDASINTETICO = lazy(() =>
  import('views/Relatorios/VENDASINTETICO/VENDASINTETICO'),
);
const ALP0008 = lazy(() =>
  import('views/Relatorios/ALP0008/ALP0008'),
);

const PRODUCAO = lazy(() => import('views/Relatorios/PRODUCAO/PRODUCAO'));
const perfil = sessionStorage.getItem('perfil');

const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Layout>
        <Route path="/dashboard" isPrivate component={DashboardPage} />
          <Route path="/produtos" isPrivate component={Produtos} />
          <Route path="/clientes" isPrivate component={Clientes} />
          <Route path="/pedidos" isPrivate component={Pedidos} />
          <Route path="/carrinho" isPrivate component={Carrinho} />
          <Route path="/relatorios" isPrivate component={Relatorios} />
          <Route path="/admin/EGR1000" isPrivate component={EGR1000} />
          <Route path="/admin/EIR4002" isPrivate component={EIR4002} />
          <Route path="/admin/EIR6000/EIR6000.js" isPrivate component={EIR6000} />
          <Route path="/admin/EIR6000/EIR6000COM.js" isPrivate component={EIR6000COM} />

          <Route path="/admin/ALP0008" isPrivate component={ALP0008} />
          <Route path="/admin/CONTASARECEBER" isPrivate component={CONTASARECEBER} />
          <Route path="/admin/PEDIDOSVENDA" isPrivate component={PEDIDOSVENDA} />
          <Route path="/admin/NOTASFISCAIS" isPrivate component={NOTASFISCAIS} />
          <Route path="/admin/FICHATECNICA" isPrivate component={FICHATECNICA} />



          <Route
            path="/admin/PRONTAENTREGA"
            isPrivate
            component={PRONTAENTREGA}
          />
          <Route path="/admin/PROGRAMACAO" isPrivate component={PROGRAMACAO} />
          <Route path="/admin/PRODUCAO" isPrivate component={PRODUCAO} />
          <Route
            path="/admin/VENDAANALITICO"
            isPrivate
            component={VENDAANALITICO}
          />
          <Route
            path="/admin/VENDASINTETICO"
            isPrivate
            component={VENDASINTETICO}
          />
          <Route path="/admin/ESTOQUEIMAGEM" isPrivate component={RELIMG} />

          {perfil === 'admin_global' ? (
            <>
              <Route path="/admin/usuarios" isPrivate component={Usuarios} />
              <Route
                path="/admin/organizacao"
                isPrivate
                component={Organizacao}
              />
              <Route path="/admin/empresa" isPrivate component={Empresa} />
              <Route path="/admin/precos" isPrivate component={Precos} />
              <Route path="/admin/galeria" isPrivate component={Galeria} />
              <Route path="/admin/regras" isPrivate component={Regras} />
            </>
          ) : perfil === 'ti'  ? (
            <>
              <Route path="/admin/usuarios" isPrivate component={Usuarios} />
              <Route path="/admin/galeria" isPrivate component={Galeria} />
            </>
          ) : (
            ''
          )}
        </Layout>
      </Switch>
    </Suspense>
  );
};

export default Routes;
