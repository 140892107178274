import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
import StoreIcon from '@material-ui/icons/Store';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Container } from './styles.js';
import { Link } from 'react-router-dom';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Divider } from '@material-ui/core'; // Importando Divider aqui

const useStyles = makeStyles(theme => ({
  searchBar: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  cardGrid: {
    flexGrow: 1,
    paddingTop: 16,
  },
  card: {
    flex: 1,
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    boxShadow: theme.shadows[3],
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: theme.shadows[8],
    },
    padding: theme.spacing(2),
  },
  icon: {
    fontSize: 50,
    color: theme.palette.primary.main,
    verticalAlign: 'top',
    marginRight: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  cardCategory: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    textTransform: 'uppercase',
  },
}));

export default function Relatorios() {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredReports, setFilteredReports] = useState([]);

  const reports = [
    {
      id: '2',
      path: '/admin/EIR6000/EIR6000.js',
      name: 'Ranking de vendas',
      desc: 'Ranking de vendas',
      img: FormatListNumberedIcon,
    },
    {
      id: '3',
      path: '/admin/EIR6000/EIR6000COM.js',
      name: 'comissão',
      desc: 'Relatório de comissão',
      img: ReceiptIcon,
    },
    {
      id: '4',
      path: '/admin/PRONTAENTREGA',
      name: 'Pronta Entrega',
      desc: 'Pronta Entrega',
      img: AssignmentIcon,
    },
    {
      id: '5',
      path: '/admin/PROGRAMACAO',
      name: 'Programação',
      desc: 'Programação',
      img: DescriptionIcon,
    },
    {
      id: '6',
      path: '/admin/EGR1000',
      name: 'Listagem de Clientes',
      desc: 'Obtem a lista de pessoas',
      img: PeopleIcon,
    },
    {
      id: '7',
      path: '/admin/ESTOQUEIMAGEM',
      name: 'Estoque Imagem',
      desc: 'Relatório de Estoque por imagem',
      img: CameraAltIcon,
    },
    {
      id: '8',
      path: '/admin/CONTASARECEBER',
      name: 'Contas a receber',
      desc: 'Relatório de contas a receber',
      img: AttachMoneyIcon,
    },
    {
      id: '9',
      path: '/admin/ALP0008',
      name: 'Análise de atividade',
      desc: 'Relatório de análise de atividade por período',
      img: AssignmentIcon,
    },
    {
      id: '10',
      path: '/admin/PEDIDOSVENDA',
      name: 'pedidos de venda',
      desc: 'Relatório de pedidos de venda por período',
      img: AttachMoneyIcon,
    },
    {
      id: '11',
      path: '/admin/NOTASFISCAIS',
      name: 'NFs',
      desc: 'Relatório de notas fiscais por período',
      img: ReceiptIcon,
    },
    {
      id: '12',
      path: '/admin/FICHATECNICA',
      name: 'Ficha Técnica',
      desc: 'Download ficha técnica',
      img: DescriptionIcon,
    },
  ].sort((a, b) => a.desc.localeCompare(b.desc));

  useEffect(() => {
    setFilteredReports(
      reports.filter(report =>
        report.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm]);

  return (
    <Container className={classes.cardGrid} maxWidth="xl">
      <Typography variant="h4" align="center" gutterBottom>
        Relatórios
      </Typography>
      <Divider style={{ marginBottom: '20px' }} />
      <TextField
        className={classes.searchBar}
        variant="outlined"
        placeholder="Pesquisar relatório..."
        onChange={e => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <Grid container spacing={2}>
        {filteredReports.map(data => (
          <Grid item key={`GridItem-${data.id}`} xs={12} sm={6} md={4} lg={3}>
            <Link to={data.path} style={{ textDecoration: 'none' }}>
              <Card className={classes.card}>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <data.img className={classes.icon} />
                  </CardIcon>
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '22px',
                      marginTop: '8px',
                    }}
                  >
                    <Typography className={classes.cardCategory}>
                      {data.name}
                    </Typography>
                  </div>
                </CardHeader>

                <CardActions>
                  <Button size="small" color="primary">
                    Acessar
                  </Button>
                </CardActions>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
